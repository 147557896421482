<!--
 * @Author: zhouyajaun
 * @Date: 2020-10-15 10:29:30
 * @LastEditTime: 2020-10-15 11:00:33
 * @LastEditors: Please set LastEditors
 * @Description: 选择用户（共用页面）
 * @FilePath: \nethallofficialaccounts\src\views\SelectWaterUser\index.vue
-->
<template>
    <div class="selectWaterUser-page">
        <div class="header">
            <span>已绑定用水账户</span>
        </div>
        <div class="content">
          <van-checkbox-group v-model="result" ref="checkboxGroup" >
                <van-checkbox  v-for="(item, index) in topDatainfo" :key="index" :name="index" @click="checkGroup(index)">
                    <div class="right-txt">
                        <div class="first-li">
                            <span>户号: {{item.accountNumber}}</span>
                        </div>
                        <div class="center-li">
                            <p class="van-multi-ellipsis--l3">{{item.address}}</p>
                        </div>
                    </div>
                </van-checkbox>
            </van-checkbox-group>
        </div>

        <div class="btnContent">
            <van-button type="info" block>绑定新用水账户</van-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'selectWaterUser',
    data() {
        return {
            // 账单筛选
            topDatainfo: [
                {
                    isChecked: false,
                    accountNumber: '6123456',
                    address: '上海市嘉定区外冈镇118弄外冈景苑26栋1401室',
                },
                {
                    isChecked: false,
                    accountNumber: '5123456',
                    address: '上海市嘉定区外冈镇118弄外冈景苑26栋1401室',
                }
            ],
        }
    },
    mounted() {

    },
    methods: {
        // 单选
        checkGroup (index) {
            this.topDatainfo[index].isChecked = !this.topDatainfo[index].isChecked
            this.isAllChecked = this.topDatainfo.every(item => item.isChecked)
            // console.log('重要-1', this.result)
            // console.log('重要-topDatainfo---1', this.topDatainfo)
        },
    }
}
</script>

<style lang="less" scoped>
.selectWaterUser-page {
    .header {
        text-align: left;
        padding-left: 16px;
        height: 56px;
        line-height: 56px;
        span {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0,0,0,0.65);
            letter-spacing: 0;
        }
    }
    .content {
        padding-top: 5px;
        padding-bottom: 10px;
        .right-txt {
            .first-li {
                margin-left: 16px;
                margin-right: 16px;
                display: flex;
                justify-content: space-between;
                padding-top: 16px;
                span {
                    font-family: PingFangSC-Regular;
                    font-size: 14px;
                    color: rgba(0,0,0,0.65);
                    letter-spacing: 0;
                    line-height: 20px;
                }
            }
            .center-li {
                margin-left: 14px;
                padding-bottom: 16px;
                padding-top: 5px;
                p {
                    margin: 0px;
                    font-family: PingFangSC-Regular;
                    font-size: 14px;
                    color: rgba(0,0,0,0.85);
                    letter-spacing: 0;
                    letter-spacing: 0;
                    line-height: 22px;
                    font-weight: 600;
                }
            }
        }
        .van-checkbox-group {
            margin-left: 16px;
            margin-right: 16px;
            margin-bottom: 15px;
        }
        .van-checkbox {
            background: #ffff;
            border-radius: 8px;
            margin-bottom: 10px;
        }
        /deep/.van-checkbox__icon {
            margin-left: 16px;
        }
    }
    .mgrA {
        margin-left: 16px;
    }
    .btnContent {
        padding-left: 16px;
        padding-right: 16px;
        /deep/.van-button--block {
            width: 90%;
            position: fixed;
            bottom: 10px;
            border-radius: 8px;
        }
    }
}
</style>
